import { useEffect, useState } from 'react';

import { EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AUTHENTICATION_FAILED_ID, UNAUTHENTICATED_ID } from '@m/constants/ResponseErrorCode';
import { MsalService } from '@m/services/MsalService';
import { showAlert } from '@m/state/alert';
import { APIResponse } from '@m/utils/http';
import LoginProgress from 'apps/portal/components/Login/LoginProgress';
import { useHistory } from 'react-router-dom';

interface LoginRedirectPageProps {
  doLogin: (props: { code: string; email: string }) => Promise<APIResponse>;
  darkMode?: boolean;
}

export default function LoginRedirectPage({ doLogin, darkMode }: LoginRedirectPageProps) {
  const history = useHistory();
  const { instance } = useMsal();
  const [errorMessage, setErrorMessage] = useState<string>();

  const onErrorRedirect = () => {
    window.localStorage.clear(); // for some reason old microsoft data is cached if you login to a diff account
    history.push('/login', { loginError: true });
  };

  useEffect(() => {
    // todo: check if we even have an msal token, else navi back to /login
    instance.addEventCallback(async (event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        const { code, email } = await MsalService.getLoginInfo();

        const loginRes = await doLogin({
          code,
          email,
        });

        if (!loginRes.success) {
          // todo: find out why this doesn't alert on admin...
          if (loginRes?.code?.id === UNAUTHENTICATED_ID) {
            showAlert({ label: 'Unauthorized', type: 'error' });
            setErrorMessage('Unauthorized');
          } else if (loginRes?.code?.id === AUTHENTICATION_FAILED_ID) {
            showAlert({ label: 'Failed Authentication', type: 'error' });
            setErrorMessage('Failed Authentication');
          } else {
            showAlert({ label: 'Failed to sign in', type: 'error' });
            setErrorMessage('Failed sign in');
          }
          return;
        }
      }

      if (
        event.eventType === EventType.LOGIN_FAILURE ||
        event.eventType === EventType.ACQUIRE_TOKEN_FAILURE
      ) {
        // eslint-disable-next-line no-console
        console.log('err', event);
        history.push('/login', { loginError: true });
      }
    });
  }, [instance, history, doLogin]);

  return (
    <LoginProgress
      darkMode={darkMode}
      errorMessage={errorMessage}
      errorRedirect={onErrorRedirect}
    />
  );
}
